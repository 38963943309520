import { Errors } from '../../apis/client-axios';
import NotificationError from '../notification/NotificationError';
import { ErrorMessage } from './error.message';

export function CustomHandleError(error: any) {
  if (error.statusCode === 500 || error.statusCode === 501) {
    NotificationError({ content: ErrorMessage[500] });
  } else if (error.statusCode === 404) {
    NotificationError({ content: ErrorMessage[404] });
  } else if (error.statusCode === 403) {
    NotificationError({ content: ErrorMessage[403] });
  } else if (error.statusCode === 401) {
    NotificationError({ content: ErrorMessage[401] });
  } else if (error.statusCode === 406) {
    NotificationError({ content: ErrorMessage[406] });
  } else {
    if (error?.extendsMessage) {
      const extendsMessage: Errors | Errors[] = error.extendsMessage;
      const errorMessage: string = Array.isArray(extendsMessage)
        ? extendsMessage.find((item) => item?.SeverityCode === 'Error')?.LongMessage || ''
        : extendsMessage?.LongMessage || '';

      NotificationError({
        content: errorMessage || ErrorMessage?.[error?.message] || ErrorMessage[500],
      });
    } else {
      NotificationError({ content: ErrorMessage?.[error?.message] ?? ErrorMessage[500] });
    }
  }
}
