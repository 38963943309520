import { Modal, ModalProps } from 'antd';
import React from 'react';
import './modalStyled.scss';

type Props = {
  label?: string;
  modalProps?: ModalProps;
  children: React.ReactNode | string | JSX.Element;
  isOpen: boolean;
  onCancel: () => void;
};
export const StyledModal: React.FC<Props> = ({ modalProps, children, isOpen, onCancel, ...rest }) => {
  return (
    <Modal
      open={isOpen}
      maskClosable={false}
      keyboard={false}
      closable={modalProps?.closable || false}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={modalProps?.footer}
      centered={true}
      {...modalProps}
      className={`modal-styled ${modalProps?.className ?? ''}`}
    >
      {children}
    </Modal>
  );
};
