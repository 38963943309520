import axios from 'axios';
import { CustomHandleError } from '../components/response/error';
import {
  AssetsApi,
  AuthApi,
  Configuration,
  PermissionsApi,
  SettingsApi,
  EbayManagerApi,
  UsersApi,
  SystemNotificationUserApi,
  ChatSupportGroupApi,
  ChatSupportMessageApi,
  PlansApi,
  EbayReturnManagerApi,
  PaymentSettingsApi,
  StorageRequestApi,
  ShippoApiApi,
  StorageItemApi,
  FileExportApi,
  ItemMessageGroupApi,
  ItemInquiryMessageApi,
  StorageProcessApi,
  SettingNotifyApi,
  BizNotificationsApi,
  PaymentsApi,
  ExchangeRateApi,
  CouponApi,
} from './client-axios';
import { USER_ROUTE_PATH } from '../constants/router';

const config = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
  accessToken: localStorage.getItem('token') || undefined,
});
export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    CustomHandleError(error?.response?.data);
    // const path = window.location.pathname.split('/');
    if (error?.response?.status === 401 || error?.response?.status === 406) {
      localStorage.removeItem('token');
      localStorage.removeItem('authUser');
      window.location.href = USER_ROUTE_PATH.SIGNIN;
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use((config) => {
  const tokenFromLocalStorage = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${tokenFromLocalStorage}`;

  return config;
});

const authApi = new AuthApi(config, undefined, axiosInstance);
const userApi = new UsersApi(config, undefined, axiosInstance);
const assetApi = new AssetsApi(config, undefined, axiosInstance);
// const permissionApi = new PermissionsApi(config, undefined, axiosInstance);
const settingApi = new SettingsApi(config, undefined, axiosInstance);
const ebayManagerApi = new EbayManagerApi(config, undefined, axiosInstance);
const ebayReturnApi = new EbayReturnManagerApi(config, undefined, axiosInstance);
const systemNotificationUserApi = new SystemNotificationUserApi(config, undefined, axiosInstance);
const plansApi = new PlansApi(config, undefined, axiosInstance);
const chatSupportGroupApi = new ChatSupportGroupApi(config, undefined, axiosInstance);
const chatSupportMessageApi = new ChatSupportMessageApi(config, undefined, axiosInstance);
const assetsApi = new AssetsApi(config, undefined, axiosInstance);
const paymentSettingsApi = new PaymentSettingsApi(config, undefined, axiosInstance);
const storageRequestApi = new StorageRequestApi(config, undefined, axiosInstance);
const storageItemApi = new StorageItemApi(config, undefined, axiosInstance);
const fileExportApi = new FileExportApi(config, undefined, axiosInstance);
const shippoApi = new ShippoApiApi(config, undefined, axiosInstance);
const itemMessageGroupApi = new ItemMessageGroupApi(config, undefined, axiosInstance);
const itemInquiryMessageApi = new ItemInquiryMessageApi(config, undefined, axiosInstance);
const storageProcessApi = new StorageProcessApi(config, undefined, axiosInstance);
const settingNotifyApi = new SettingNotifyApi(config, undefined, axiosInstance);
const bizNotificationsApi = new BizNotificationsApi(config, undefined, axiosInstance);
const paymentsApi = new PaymentsApi(config, undefined, axiosInstance);
const exchangeRateApi = new ExchangeRateApi(config, undefined, axiosInstance);
const couponApi = new CouponApi(config, undefined, axiosInstance);

export {
  authApi,
  userApi,
  settingApi,
  assetApi,
  systemNotificationUserApi,
  ebayManagerApi,
  chatSupportGroupApi,
  chatSupportMessageApi,
  plansApi,
  assetsApi,
  ebayReturnApi,
  paymentSettingsApi,
  storageRequestApi,
  storageItemApi,
  fileExportApi,
  shippoApi,
  itemMessageGroupApi,
  itemInquiryMessageApi,
  storageProcessApi,
  settingNotifyApi,
  bizNotificationsApi,
  paymentsApi,
  exchangeRateApi,
  couponApi,
};
