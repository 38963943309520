export const ErrorMessage = {
  500: 'エラーが発生しました。',
  401: 'ページが見つかっていません。',
  403: 'エラーが発生しました。',
  404: 'アクセスしようとしたページは表示できませんでした。',
  406: '操作を実行する権限がありません',
  DATA_EXISTS: 'DATA_EXISTS',
  FAILURE_SYNCED: 'FAILURE_SYNCED',
  NUMBER_OF_PLAN_REACH_LIMIT: 'NUMBER_OF_PLAN_REACH_LIMIT',
  USER_OR_PASSWORD_IS_WRONG: 'メールアドレスまたはパスワードが正しくありません。',
  INVALID_CURRENT_PASSWORD: 'INVALID_CURRENT_PASSWORD',
  LABEL_CREATED_ERROR: '送り状ラベル発行失敗',
  TOKEN_INVALID: '認証に失敗しました',
  LIMITED_TIME: '新しいリクエストはまだ送信できません',
  ERROR_UPDATING: 'UPDATE_ERROR',
  ERROR_DELETING: 'DELETE_ERROR',
  ERROR_CREATING: 'CREATE_ERROR',
  ERROR_GETTING: 'GET_ERROR',
  ERROR_NOT_FOUND: 'ページが見つかっていません。',
  CANCELLED_MEMBERSHIP: '退会したアカウントです。',
  ERROR_NOT_OWNER: '別の顧客アカウントと連携されています。',
  NOT_HAVE_PERMISSION: '操作を実行する権限がありません',
  CHARGE_MONEY_FAILED: '決済に失敗しました。カードや内容を確認の上再度お試しください。',
  EXPIRED_REFRESH_TOKEN_USER: 'ebayアカウントと連携取れていません。連携状況を確認していください。',
  EXPIRED_REFRESH_TOKEN_ADMIN: 'ebayアカウントと連携取れていません。ユーザに問い合わせしてください。',
  ADDRESS_NOT_VALID: '住所に間違いがあります。倉庫担当者にお問い合わせください。チャットサポートで受付します。',
};
