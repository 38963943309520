import { useMutation } from '@tanstack/react-query';
import { Button, Checkbox, Form, Image, Input, Radio, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ebayManagerApi,
  plansApi,
  shippoApi,
  storageItemApi,
  storageProcessApi,
  storageRequestApi,
} from '../../../../apis';
import {
  CreateShipmentDto,
  CreateShipToBuyerDto,
  EbayCollectAndRemitTaxesEbayReference,
  LineItem,
  ParcelsDtoDistanceUnitEnum,
  ParcelsDtoMassUnitEnum,
  PlanVersionExtendDto,
  ShippingAddress,
  StorageItemDto,
  Transaction,
  TransactionArray,
} from '../../../../apis/client-axios';
import SvgIconBackLarge from '../../../../components/@svg/SvgBackLarge';
import { USER_ROUTE_PATH } from '../../../../constants/router';
import './ship2buyerRequest.scss';
import { SvgINoti } from '../../../../components/@svg/SvgINoti';
import { AxiosError } from 'axios';
import ButtonAgreeWhite from '../../../../components/buttonCustom/ButtonAgreeWhite';
import ButtonAgree from '../../../../components/buttonCustom/ButtonAgree';
import ButtonCancel from '../../../../components/buttonCustom/ButtonCancel';
import { ModalInformation } from '../../../../components/ModalInformation';
import NotificationError from '../../../../components/notification/NotificationError';
import { TMessagesCarrier } from '../../return/storage-request';
import NotificationSuccess from '../../../../components/notification/NotificationSuccess';
import ButtonArrowBack from '../../../../components/buttonCustom/ButtonArrowBack';
import CustomCheckbox from '../../../../components/checkbox';
type TDataRequest = {
  carrier: string;
  carrierPrice: number;
  errorInputHScode: boolean;
  errorInputItemPrice: boolean;
  HSCode: string;
  insurance: number;
  isInsurance: boolean;
  itemPrice: number;
  openModalInformation: boolean;
  orderAccountEbayId: number;
  orderBuyerEiasToken: string;
  orderItemId: string;
  orderItemName: string;
  orderItemPrice: number;
  orderItemPriceCurrency: string;
  orderItemSKU: string;
  orderShippingAddress: string;
  orderShippingAddress2: string;
  orderShippingName: string;
  orderShippingTelNo: string;
  orderShippingZipCode: string;
  orderStatus: string;
  quantityPurchased: number;
  shippingAddress: string;
  shippingAddress2: string;
  shippingName: string;
  shippingPlan: string;
  shippingRate: string;
  shippingTelNo: string;
  shippingZipCode: string;
  transactionId: string;
};
export default function Ship2BuyerRequest() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const itemStorage = searchParams.get('itemStorage');
  const orderId = searchParams.get('orderId');
  const itemId = searchParams.get('itemId');
  const userEbayId = searchParams.get('userEbayId');
  const IOSS = searchParams.get('IOSS');
  const phone = searchParams.get('phone');
  const lineItemID = searchParams.get('lineItemID');
  const [dataRequest, setDataRequest] = useState<TDataRequest>({
    carrier: '',
    carrierPrice: 0,
    errorInputHScode: false,
    errorInputItemPrice: false,
    HSCode: '',
    insurance: 0,
    isInsurance: false,
    itemPrice: 0,
    openModalInformation: false,
    orderAccountEbayId: 0,
    orderBuyerEiasToken: '',
    orderItemId: '',
    orderItemName: '',
    orderItemPrice: 0,
    orderItemPriceCurrency: '',
    orderItemSKU: '',
    orderShippingAddress: '',
    orderShippingAddress2: '',
    orderShippingName: '',
    orderShippingTelNo: phone,
    orderShippingZipCode: '',
    orderStatus: '',
    quantityPurchased: 0,
    shippingAddress: '',
    shippingAddress2: '',
    shippingName: '',
    shippingPlan: '',
    shippingRate: '',
    shippingTelNo: '',
    shippingZipCode: '',
    transactionId: '',
  });
  const handleClickBack = () => navigate(`${USER_ROUTE_PATH.SHIP_BUYER_REQUEST}/${itemStorage}`);
  const { mutate: CreateShipToBuyer, isPending: loadingCreateShip2Buyer } = useMutation({
    mutationKey: ['submit'],
    mutationFn: (data: CreateShipToBuyerDto) =>
      storageProcessApi.storageProcessControllerCreateShipToBuyerRequest(data),
    onSuccess: ({ data }) => {
      NotificationSuccess({ content: '発送依頼が登録されました。' });
      navigate(`${USER_ROUTE_PATH.RETURN_ITEM}`);
    },
    onError: ({ response: { data } }: AxiosError) => {
      // NotificationError({ content: (data as any)?.message });
    },
  });
  const {
    data: itemDetail,
    mutate: getOneItem,
    isPending: loadingGetOne,
  } = useMutation({
    mutationKey: [itemStorage],
    mutationFn: (id: string) => storageItemApi.storageItemControllerGetOneItemManagementUser(id),
    onError: ({ response: { data } }: AxiosError) => {},
  });
  const {
    data: carrier,
    mutate: getCarrier,
    isPending: loadingCarrier,
  } = useMutation({
    mutationKey: ['carrier'],
    mutationFn: (data: CreateShipmentDto) => shippoApi.shippoApiControllerCreateShipment(data),
    onSuccess: ({ data }) => {
      const listRates = Object.values(data.rates).filter((v) => v);
      if (!listRates.length) {
        handleClickBack();
        data?.messages?.forEach((v: TMessagesCarrier) => {
          const split = v.text.split(': ');
          const content = `${v.source}: ${split[1] ?? split[0] ?? 'ERROR'}`;
          NotificationError({ content });
        });
      }
    },
    onError: ({ response: { data } }: AxiosError) => {
      // NotificationError({ content: (data as any)?.message });
      handleClickBack();
    },
  });
  const {
    data: orderEbay,
    mutate: getOneOrder,
    isPending: loadingGetOneOrder,
  } = useMutation({
    mutationKey: [orderId],
    mutationFn: (data: { orderId: string; userEbayId: string }) =>
      ebayManagerApi.ebayManagerControllerGetOneOrderReturnFromEbay(data.orderId, data.userEbayId),
    onError: ({ response: { data } }: AxiosError) => {},
  });
  const fetchApi = async () => await Promise.all([getOneItem(itemStorage), getOneOrder({ orderId, userEbayId })]);

  const mapAddress = (ShippingAddress: ShippingAddress) => {
    const addressLine1 = ShippingAddress?.Street1 || '';
    const addressLine2 = ShippingAddress?.Street2 || '';
    const city = ShippingAddress?.CityName || '';
    const countryCode = ShippingAddress?.Country || '';
    const postalCode = ShippingAddress?.PostalCode || '';
    const stateOrProvince = ShippingAddress?.StateOrProvince || '';

    return `${addressLine1}, ${addressLine2 ? `${addressLine2},` : ''} ${city}, ${stateOrProvince} ${postalCode}, ${countryCode}`;
  };

  useEffect(() => {
    if (orderId && itemStorage && userEbayId) fetchApi();
  }, [orderId, itemStorage, userEbayId]);

  useEffect(() => {
    if (orderEbay?.data?.BuyerUserID && itemDetail?.data?.id) {
      const Transaction = orderEbay?.data?.TransactionArray?.Transaction;
      const item = Array.isArray(Transaction)
        ? (Transaction as Transaction[])?.find((v) => v.Item.ItemID === itemId)
        : Transaction;
      const orderItemPrice = +item?.TransactionPrice?.['_@ttribute'];
      const orderShippingTelNo =
        orderEbay?.data?.ShippingAddress?.Phone !== 'Invalid Request' ? orderEbay?.data?.ShippingAddress?.Phone : phone;

      setDataRequest({
        ...dataRequest,
        insurance: +((1.25 * orderItemPrice) / 100).toFixed(2),
        itemPrice: orderItemPrice,
        orderAccountEbayId: itemDetail?.data?.ebayReturns?.accountEbayId,
        orderBuyerEiasToken: orderEbay?.data?.EIASToken,
        orderItemId: item?.Item?.ItemID,
        orderItemName: item?.Item?.Title,
        orderItemPrice: orderItemPrice,
        orderItemPriceCurrency: item?.TransactionPrice?.currencyID,
        orderItemSKU: item?.Item?.SKU,
        orderShippingAddress: orderEbay?.data?.ShippingAddress?.Street1 || orderEbay?.data?.ShippingAddress?.Street2,
        orderShippingAddress2: orderEbay?.data?.ShippingAddress?.Street2 ?? '',
        orderShippingName: orderEbay?.data?.ShippingAddress?.Name,
        orderShippingTelNo,
        orderShippingZipCode: orderEbay?.data?.ShippingAddress?.PostalCode,
        orderStatus: orderEbay?.data?.OrderStatus,
        quantityPurchased: +item?.QuantityPurchased,
        shippingAddress: orderEbay?.data?.ShippingAddress?.Street1 || orderEbay?.data?.ShippingAddress?.Street2,
        shippingAddress2: orderEbay?.data?.ShippingAddress?.Street2 ?? '',
        shippingName: orderEbay?.data?.ShippingAddress?.Name,
        shippingTelNo: orderShippingTelNo,
        shippingZipCode: orderEbay?.data?.ShippingAddress?.PostalCode,
        transactionId: item?.TransactionID,
      });
      getCarrier({
        ebayAccountId: itemDetail?.data?.ebayReturns?.accountEbayId,
        address_to: {
          name: orderEbay?.data?.ShippingAddress?.Name,
          street1: orderEbay?.data?.ShippingAddress?.Street1 || orderEbay?.data?.ShippingAddress?.Street2,
          street2: orderEbay?.data?.ShippingAddress?.Street2 ?? '',
          city: orderEbay?.data?.ShippingAddress?.CityName,
          state: orderEbay?.data?.ShippingAddress?.StateOrProvince,
          zip: orderEbay?.data?.ShippingAddress?.PostalCode,
          country: orderEbay?.data?.ShippingAddress?.Country,
        },
        parcels: [
          {
            distance_unit: ParcelsDtoDistanceUnitEnum.Cm,
            height: itemDetail?.data?.storageHeight.toString(),
            length: itemDetail?.data?.storageLength.toString(),
            mass_unit: ParcelsDtoMassUnitEnum.Kg,
            weight: itemDetail?.data?.storageWeight.toString(),
            width: itemDetail?.data?.storageWidth.toString(),
            metadata: '',
          },
        ],
      });
    }
  }, [orderEbay, itemDetail]);

  return (
    <div className="request-ship-2-buyer">
      {itemDetail?.data && (
        <>
          <ButtonArrowBack onClick={handleClickBack} />
          <div className="request-ship-2-buyer-container">
            <p className="title">配送情報</p>
            <p>バイヤー情報</p>
            {orderEbay?.data?.BuyerUserID && (
              <div className="content-partitions address">
                <div className="content-partitions-item">
                  <div className="content-partitions-item-address">
                    名前<span>:</span>
                  </div>
                  <span>{orderEbay?.data?.ShippingAddress?.Name}</span>
                </div>
                <div className="content-partitions-item">
                  <div className="content-partitions-item-address">
                    住所<span>:</span>
                  </div>
                  <span>{mapAddress(orderEbay?.data?.ShippingAddress)}</span>
                </div>
                <div className="content-partitions-item">
                  <div className="content-partitions-item-address">
                    電話番号<span>:</span>
                  </div>
                  <span>{dataRequest.orderShippingTelNo}</span>
                </div>
              </div>
            )}
            <p>パッケージ情報</p>
            <div className="content-partitions size size-package">
              {orderEbay?.data?.ShippingAddress?.Country !== 'US' && (
                <div className="hs">
                  <div className="hs-price">
                    <div>HSコード</div>
                    <Input
                      style={{ borderColor: dataRequest.errorInputHScode ? 'red' : '' }}
                      onChange={({ target: { value } }) => {
                        if (value.length > 255) {
                          setDataRequest({ ...dataRequest, errorInputHScode: true });
                        } else {
                          setDataRequest({ ...dataRequest, errorInputHScode: false, HSCode: value });
                        }
                      }}
                    />
                  </div>
                  <div className="hs-price">
                    <div>申告価格（$）</div>
                    <Input
                      type="number"
                      style={{ borderColor: dataRequest.errorInputItemPrice ? 'red' : '' }}
                      onChange={({ target: { value } }) => {
                        if (+value < 0 || +value > 999999999999) {
                          setDataRequest({ ...dataRequest, errorInputItemPrice: true });
                        } else {
                          const itemPrice = +value ?? dataRequest.orderItemPrice;
                          setDataRequest({
                            ...dataRequest,
                            itemPrice,
                            errorInputItemPrice: false,
                            insurance: +((1.25 * (itemPrice || dataRequest.orderItemPrice)) / 100).toFixed(2),
                          });
                        }
                      }}
                    />
                  </div>
                  {IOSS && (
                    <div className="hs-price">
                      <div className="IOSS-label">IOSS :</div>
                      <div className="IOSS">{IOSS}</div>
                    </div>
                  )}
                </div>
              )}
              <div className="size-detail">
                <div className="centimeter">梱包サイズ・重量</div>
                <div className="content-partitions-item-size">
                  <span>
                    <span className="content-partitions-item-size-item">
                      縦<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">
                      {itemDetail?.data?.storageLength} cm
                    </span>
                  </span>
                  <span>
                    <span className="content-partitions-item-size-item">
                      横<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">{itemDetail?.data?.storageWidth} cm</span>
                  </span>
                  <span>
                    <span className="content-partitions-item-size-item">
                      高さ<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">
                      {itemDetail?.data?.storageHeight} cm
                    </span>
                  </span>
                  <span>
                    <span className="content-partitions-item-size-item">
                      重量<span className="colon">:</span>
                    </span>
                    <span className="content-partitions-item-size-item-value">
                      {itemDetail?.data?.storageWeight} kg
                    </span>
                  </span>
                </div>
              </div>
              <div className="btn-group">
                <ButtonAgreeWhite
                  type="small"
                  value="問い合わせ"
                  onClick={() =>
                    window.open(
                      `${USER_ROUTE_PATH.RETURN_ITEM_DETAIL}/${itemStorage}?orderId=${orderId}&itemId=${itemId}&userEbayId=${userEbayId}&itemStorage=${itemStorage}&phone=${phone}${IOSS ? `&IOSS=${IOSS}` : ''}`,
                    )
                  }
                />
                <div>梱包についてご要望がある場合お問い合わせください</div>
              </div>
            </div>
            <p>配送会社</p>
            <div className="content-partitions carrier">
              <Radio.Group>
                <div className="carrier">
                  {carrier?.data?.rates &&
                    Object.values(carrier?.data?.rates)
                      .filter((v) => v)
                      .map((item, index) => (
                        <label
                          className={`carrier-item ${dataRequest.carrier === item.provider && 'active'}`}
                          key={index}
                        >
                          <Radio
                            checked={dataRequest.carrier === item.provider}
                            value={item.provider}
                            id={item.provider}
                            onChange={() => {
                              setDataRequest({
                                ...dataRequest,
                                carrier: item.provider,
                                carrierPrice: +item.amount,
                                shippingPlan: item.servicelevel.displayName ?? item.servicelevel.name,
                                shippingRate: item.objectId,
                              });
                            }}
                          />
                          <div className="d-flex align-items-center" style={{ gap: '12px' }}>
                            <Image
                              preview={false}
                              width={64}
                              src={`/assets/icons/carrier/${item?.provider?.toLowerCase()}.png`}
                            />
                            <div>
                              <span className="font-base color-title font-weight-600 font-size-16">
                                {item?.provider}
                              </span>
                              <div>
                                <span className="font-base color-sub-title font-weight-600 font-size-16">
                                  ${item?.amount}
                                </span>
                              </div>
                              <div className="font-base color-sub-title font-weight-500 font-size-12 m-t-4 text-overflow-130">
                                {item?.servicelevel?.displayName ?? item?.servicelevel?.name}
                              </div>
                              <div className="font-base color-cancel font-weight-500 font-size-10 m-t-2">
                                About {item?.estimatedDays} days
                              </div>
                            </div>
                          </div>
                          {item.provider === 'USPS' && (
                            <div
                              className="carrier-item-information"
                              onClick={() => setDataRequest({ ...dataRequest, openModalInformation: true })}
                            >
                              <SvgINoti />
                            </div>
                          )}
                        </label>
                      ))}
                </div>
              </Radio.Group>
            </div>
            <p>オプション</p>
            <div className="content-partitions insurance">
              <div>
                <CustomCheckbox
                  className="insurance_item_checkbox"
                  onChange={({ target: { checked } }) => {
                    setDataRequest({ ...dataRequest, isInsurance: checked });
                  }}
                />
                {/* <Checkbox
                  className="insurance_item_checkbox"
                  onChange={({ target: { checked } }) => {
                    setDataRequest({ ...dataRequest, isInsurance: checked });
                  }}
                /> */}
                <label>保険料</label>
              </div>
              <div className="insurance_item_price">${dataRequest.insurance}</div>
            </div>
            <div className="total">
              <div>合計金額</div>
              <div>
                ${(dataRequest.carrierPrice + (dataRequest.isInsurance ? dataRequest.insurance : 0)).toFixed(2)}
              </div>
            </div>
            <div className="request-ship-2-buyer-button-wrap">
              <ButtonCancel value="戻る" onClick={handleClickBack} />
              <ButtonAgree
                disabled={!dataRequest.carrier || dataRequest.errorInputHScode || dataRequest.errorInputItemPrice}
                value="確定"
                onClick={() => {
                  CreateShipToBuyer({
                    hsCode: dataRequest.HSCode,
                    ioss: IOSS ?? '',
                    isInsurance: dataRequest.isInsurance,
                    itemPrice: dataRequest.itemPrice ?? dataRequest.orderItemPrice,
                    orderAccountEbayId: dataRequest.orderAccountEbayId,
                    orderBuyerCountry: orderEbay?.data?.ShippingAddress?.Country,
                    orderBuyerEiasToken: dataRequest.orderBuyerEiasToken,
                    orderBuyerId: orderEbay?.data?.BuyerUserID,
                    orderId: orderId,
                    orderItemId: dataRequest.orderItemId,
                    orderItemName: dataRequest.orderItemName,
                    orderItemPrice: dataRequest.orderItemPrice,
                    orderItemPriceCurrency: dataRequest.orderItemPriceCurrency,
                    orderItemSKU: dataRequest.orderItemSKU,
                    orderShippingAddress: dataRequest.orderShippingAddress,
                    orderShippingAddress2: dataRequest.orderShippingAddress2,
                    orderShippingName: dataRequest.orderShippingName,
                    orderShippingTelNo: dataRequest.orderShippingTelNo,
                    orderShippingZipCode: dataRequest.orderShippingZipCode,
                    orderStatus: dataRequest.orderStatus,
                    orderTransactionId: dataRequest.transactionId,
                    quantityPurchased: dataRequest.quantityPurchased,
                    shippingAddress: dataRequest.shippingAddress,
                    shippingAddress2: dataRequest.shippingAddress2,
                    shippingCarrier: dataRequest.carrier,
                    shippingName: dataRequest.shippingName,
                    shippingPlan: dataRequest.shippingPlan,
                    shippingRate: dataRequest.shippingRate,
                    shippingTelNo: dataRequest.shippingTelNo,
                    shippingZipCode: dataRequest.shippingZipCode,
                    storageItemId: +itemStorage,
                    lineItemId: lineItemID,
                  });
                }}
              />
            </div>
          </div>
          <ModalInformation
            content={
              'USPSは梱包サイズや重量が違うと、追加料金が発生しやすい傾向があります。USPSが1割ほど安いくらいの違いであれば、USPS以外をお勧めします。'
            }
            onCancel={() => setDataRequest({ ...dataRequest, openModalInformation: false })}
            open={dataRequest.openModalInformation}
            title={'USPS'}
          />
        </>
      )}
      {(loadingGetOne || loadingGetOneOrder || loadingCarrier || loadingCreateShip2Buyer) && (
        <div className="loading">
          <Spin />
        </div>
      )}
    </div>
  );
}
